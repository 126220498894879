//import 'fo/scss/components/_loader'

/*
 * Class permettant de faire des calls ajax pour le chargement de partial
 * Parameters :
 *  $view => Div qui va contenir le retour de notre call ajax et qui contient les différents paramètres
 *  Param => Contient les objets data envoyé lors du call
 */

export default class LoadAjax {

    constructor($view) {

        this.view = $view;

        // Récupération de l'Url qui retourne notre vue
        this.url = this.view.data("url");
        // Permet de passer des paramètres dans le call ajax
        this.param = {};

        // Permet d'afficher un loader dans la div en attendant le résultat
        this.loader = false;
        if (this.view.data("loader") === true) {
            this.loader = true;
        }

        // Permet de savoir si on doit Refresh un partial
        this.refresh = false;
        // Permet de savoir tous les combien de temps on refresh la partial
        this.time = 10000; // 1 minute 
        if (this.view.data("refresh") === true) {
            this.refresh = true;

            if (this.view.data("time") !== undefined) {
                this.time = this.view.data("time");
            }
        }

        this.Init();
    }

    Init() {
        var self = this;

        self.LoadAjax(self.param);

        // Si on doit refresh
        if (self.refresh === true) {
            setInterval(function () {
                self.LoadAjax(self.param);
            }, self.time);
        }
    }

    LoadAjax(param) {
        var self = this;

        // Gestion du loader
        if (self.loader === true) {
            $(self.view).html("<div class='loader'><div></div></div>");
        }

        $.ajax({
            type: "GET",
            cache: false, // Cache sur Edge...
            url: self.url,
            data: param,
            success: function (data) {
                $(self.view).html(data);

                // On s'assure de bind les éventuels components liés a la view intégrée
                App.Kernel.bindComponents(self.view);
            }
        });
    }
}
