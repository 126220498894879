import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import './less/main.less';

import 'slick-carousel';
import 'gsap';
import 'scrollmagic';
import 'imask';
import 'imagesloaded';

import './js/menu';

import './js/utils';
import './js/popup';
import './js/main';
import './js/components/candidate'
import './js/components/cookie-banner'
import './js/components/player-video'


import App from '6tm-components';
window.App = App;

//import 'bootstrap';
//import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
//import 'jquery-validation-unobtrusive';
//import Globalize from '6tm-globalize';
//import '6tm-globalize/src/cultures/fr-FR';

//window.Globalize = Globalize;

//import 'Respond.js/dest/respond.src'; // Uselfull ?
import LoadVideo from './js/components/player-video'
import CookieBanner from './js/components/cookie-banner'
import Candidate from './js/components/candidate'
import toastr from 'toastr';
import AjaxDropdownlist from './js/components/ajax-dropdownlist';
import LoadAjax from './js/components/load-ajax';
import Unimplemented from './js/components/unimplemented';



window.LoadVideo = LoadVideo;
window.CookieBanner = CookieBanner;
window.Candidate = Candidate;
window.toastr = toastr;

// à déporter
function ImportComponents(components) {
    components.keys().forEach(key => {
        var component = components(key);
        App.Kernel

            .registerComponent(component.default.name, component.default);
    });
}


ImportComponents(require.context('./js/components/', true, /\.(js)$/));
//ImportComponents(require.context('./js/pages/', true, /\.(js)$/));

App.Kernel
    .registerComponent('AjaxDropdownlist', AjaxDropdownlist)
    .registerComponent('LoadAjax', LoadAjax)
    .registerComponent('Unimplemented', Unimplemented)
    .registerComponent('Candidate', Candidate)
    .registerComponent('CookieBanner', CookieBanner)
    .registerComponent('LoadVideo', LoadVideo)

App.Kernel.bindComponents($("body"));

