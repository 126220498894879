import ScrollMagic from "scrollmagic";
require('animation.gsap');
require('debug.addIndicators');
import IMask from 'imask';
import { Carousel } from "../../wwwroot/lib/bootstrap/dist/js/bootstrap.bundle";

function isTouchDevice() {
    try { document.createEvent("TouchEvent"); return true; }
    catch (e) { return false; }
}

function isMobileSize() {
    return (window.matchMedia("(max-width: 992px)").matches);
}

$(document).ready(function () {
    let userAgent = navigator.userAgent.toLowerCase();
    $.browser = {
        version: (userAgent.match(/.+(?:rv|it|ra|ie|me)[/: ]([d.]+)/) || [])[1],
        chrome: /chrome/.test(userAgent),
        safari: /webkit/.test(userAgent) && !/chrome/.test(userAgent),
        opera: /opera/.test(userAgent),
        msie: /msie/.test(userAgent) && !/opera/.test(userAgent),
        mozilla: /mozilla/.test(userAgent) && !/(compatible|webkit)/.test(userAgent)
    };
    Main.init($(document));

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

});

let Main = {

    init: function (dom) {
        var scrollTop = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scrollTop > 0) {
            if (!isMobileSize()) {
                $('.header-menuMaster').addClass('isFixed');
            }
        }
        var controller = new ScrollMagic.Controller();

        var scene = [];
        var num = 0;
        $(".animate-show").each(function (i) {
            if ($(this).hasClass('animate-stragger-from')) {
                var classElement = $(this).data("class-element");

                var topSelected = null;
                $('.' + classElement, $(this)).each(function () {
                    if ($(this).position().top != topSelected) {
                        if (topSelected) {
                            var tween = TweenMax.staggerFromTo("." + classElement + "-" + num, 0.8, { top: 100, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo }, 0.15);
                            scene[num] = new ScrollMagic.Scene({ triggerElement: "#animate-show-" + num, duration: 0 })
                                .setTween(tween)
                                //.addIndicators({name: "css"})
                                .addTo(controller);
                        }

                        num++;
                        topSelected = $(this).position().top;
                        $(this).parent().addClass('relative').prepend($("<div class='animation-trigger' id='animate-show-" + num + "'></div>"));
                    }
                    $(this).addClass(classElement + "-" + num);
                });
                var tween = TweenMax.staggerFromTo("." + classElement + "-" + num, 0.8, { top: 100, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo }, 0.15);
                scene[num] = new ScrollMagic.Scene({ triggerElement: "#animate-show-" + num, duration: 0 })
                    .setTween(tween)
                    //.addIndicators({name: "css"})
                    .addTo(controller);
                num++;
            }
            else {
                $(this).parent().addClass('relative').prepend($("<div class='animation-trigger' id='animate-show-" + num + "'></div>"));
                $(this).addClass('animate-element-' + i);
                var tween = TweenMax.fromTo('.animate-element-' + num, 0.8, { top: 100, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo });
                scene[num] = new ScrollMagic.Scene({ triggerElement: "#animate-show-" + num, duration: 0 })
                    .setTween(tween)
                    //.addIndicators({name: "css"})
                    .addTo(controller);
                num++;
            }
        });

        let animateFirstShow;
        if (animateFirstShow = $('.animate-first-show').orNot()) {
            let tweenAnimateFirstShow = new TimelineMax();

            tweenAnimateFirstShow.fromTo($('.header-top'), 1, { top: -20, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOut }, "#step");

            animateFirstShow.each(function (i) {
                var topFinal = $(this).position().top;
                tweenAnimateFirstShow.fromTo($(this), 1, { top: topFinal + 50 }, { top: topFinal, ease: Linear.easeOutExpo }, "#step+=" + (0.2 * i));
                tweenAnimateFirstShow.fromTo($(this), 1.5, { opacity: 0 }, { opacity: 1, ease: Linear.easeOut }, "#step+=" + (0.2 * i));
                if (i + 1 == animateFirstShow.length) {
                    tweenAnimateFirstShow.play();
                }
            });
        }


        let blockActivite;
        if (blockActivite = $('.block-activites').orNot()) {
            var blockPositionTop = $('.activites', blockActivite).offset().top;
            var blockHeight = $('.activites', blockActivite).height();
            var element = $(".activites-contenu", blockActivite);
            var positionForSlide = element.position().left;
            $(window).scroll(function (event) {
                scrollTop = $(window).scrollTop();
                windowHeight = $(window).height();
                if (!isMobileSize()) {
                    if (((scrollTop + windowHeight) > blockPositionTop) && ((scrollTop) < (blockPositionTop + blockHeight))) {
                        var per = ((scrollTop + windowHeight - blockPositionTop) / windowHeight) * 100;
                        var newPosition = positionForSlide - (positionForSlide * per / 100) - 52;
                        if (positionForSlide > newPosition) {
                            element.css({ left: newPosition + 'px' });
                        }
                    }
                }
            });
        }


        var tweenMenuElement = new TimelineMax();
        tweenMenuElement.to($('.header-menu-popin-background'), 0.2, { top: 0, ease: Linear.easeOutExpo }, '#StartAnim')
            .fromTo($('.header-menu-popin .menu-titre'), 0.3, { top: 20, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo }, "#step2")
            .fromTo($('.header-menu-popin .header-menu'), 0.3, { top: 20, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo }, "#step2+=0.1")
            .fromTo($('.header-menu-popin .menu-images-fond'), 0.3, { top: 20, opacity: 0 }, { top: 0, opacity: 1, ease: Linear.easeOutExpo }, "#step2+=0.2")
            .pause();

        $('.trigger-open-menu-master', $(dom)).click(function () {
            if ($('body').hasClass('menu-is-open')) {
                $('body').removeClass('menu-is-open');
                tweenMenuElement.timeScale(1);
                tweenMenuElement.reverse();
            }
            else {
                $('body').addClass('menu-is-open');
                tweenMenuElement.timeScale(1);
                tweenMenuElement.play();
            }
        });


        if ($('body').hasClass("bandeau-has-image")) {
            var returnTexte = $(".button-return-content .texte").clone();
            returnTexte.addClass('black');
            $(".button-return-content .link .rotate").append(returnTexte);

            var returnPicto = $(".button-return-content .picto").clone();
            returnPicto.addClass('black');
            $(".button-return-content .link .pictos").append(returnPicto);

            var logo = $(".header-logo-content .logo").clone();
            logo.addClass('black');
            $(".header-logo-content .logo-content").append(logo);
        }

        var scrollTop = $(window).scrollTop();
        var windowHeight = $(window).height();
        Main.changeColorMenu(scrollTop, windowHeight);

        $(window).scroll(function (event) {
            var scrollTop = $(window).scrollTop();
            var windowHeight = $(window).height();
            Main.changeColorMenu(scrollTop, windowHeight);
            if (!isMobileSize()) {
                if (scrollTop > 0) {
                    $('.header-menuMaster').addClass('isFixed');
                }
                else {
                    $('.header-menuMaster').removeClass('isFixed');
                }
                if (blockActivite) {
                    if (((scrollTop + windowHeight) > blockPositionTop) && ((scrollTop) < (blockPositionTop + blockHeight))) {
                        var per = ((scrollTop + windowHeight - blockPositionTop) / windowHeight) * 100;
                        var newPosition = positionForSlide - (positionForSlide * per / 100) - 52;
                        if (positionForSlide > newPosition) {
                            element.css({ left: newPosition + 'px' });
                        }
                    }
                }
            }
        });
        $(window).resize(function () {
            if (!isMobileSize()) {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > 25) {
                    $('.header-menuMaster').addClass('isFixed');
                }
                if (blockActivite) {
                    if (((scrollTop + windowHeight) > blockPositionTop) && ((scrollTop) < (blockPositionTop + blockHeight))) {
                        var per = ((scrollTop + windowHeight - blockPositionTop) / windowHeight) * 100;
                        var newPosition = positionForSlide - (positionForSlide * per / 100) - 52;
                        if (positionForSlide > newPosition) {
                            element.css({ left: newPosition + 'px' });
                        }
                    }
                }
            }
            else {
                $(".activites-contenu", blockActivite).removeAttr('style');
                $('.header-menuMaster').removeClass('isFixed');
            }
        });

        var infinite = false;
        let caroussel;
        if (caroussel = $('.caroussel', dom).orNot()) {
            if (!caroussel.hasClass("notCarrousel")) {
                if (caroussel.data("infinite") == false) {
                    $(".carroussel-prev").hide();
                }
                if (caroussel.data("count") == 3) {
                    $(".carroussel-prev").hide();
                    $(".carroussel-next").hide();
                }
                caroussel.slick({
                    variableWidth: true,
                    initialSlide: 0,
                    slidesToShow: caroussel.data("nb-element"),
                    centerMode: false,
                    slidesToScroll: 1,
                    arrows: false,
                    infinite: infinite,
                    dots: false,
                    fade: false,
                    adaptiveHeight: false,
                    responsive: [
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ]
                });

                let carousseulNavigate;
                if (carousseulNavigate = $('.carroussel-navigate', dom).orNot()) {
                    carousseulNavigate.click(function () {
                        
                        if ($(this).hasClass('carroussel-prev')) {
                            caroussel.slick("slickPrev");
                            if ($(".slick-current").data("slick-index") == 0) {
                                $(".carroussel-prev").hide();
                                $(".carroussel-next").show();
                            }
                            else {
                                $(".carroussel-next").show();
                            }
                        }
                        else {
                            caroussel.slick("slickNext"); 
                            if ($(".slick-current").data("slick-index") == caroussel.data("count") - 3) {
                                $(".carroussel-next").hide();
                                $(".carroussel-prev").show();
                            }
                            else {
                                $(".carroussel-prev").show();
                            }
                        }
                    });

                }
            }
        }



        var fontContentBackground;
        if (fontContentBackground = $('.font-content-background.with-clip', dom).orNot()) {

            var texte = $('.font-content-background.with-clip', dom).html();
            texte = texte.repeat(2);
            $('.font-content-background', dom).html(texte);
            var mouseX = 0, mouseY = 0;
            var traX = 0, traY = 0;
            var xp = 0, yp = 0;

            $(document, dom).mousemove(function (e) {
                fontContentBackground.addClass('start-moving');

                mouseX = e.pageX;
                mouseY = e.pageY;

                var offsetTop = $('.bandeau-top-contenu', dom).offset().top + 450;
                var offsetLeft = $('.bandeau-top-contenu', dom).offset().left + 450;
                var calculateForY = Math.tan(14 * Math.PI / 180) * mouseY;
                var calculateForX = Math.tan(14 * Math.PI / 180) * mouseX;

                traY = mouseY - (offsetTop - calculateForX);
                traX = mouseX - (offsetLeft + calculateForY);
            });
            var loop = setInterval(function () {
                if (fontContentBackground.hasClass("start-moving")) {
                    xp += ((traX - xp) / 8);
                    yp += ((traY - yp) / 8);
                    fontContentBackground.css({ "background-position": xp + "px " + yp + "px" });
                }
            }, 30);
        }


        let blockMetierListe;
        let timeForNotHover;
        if (blockMetierListe = $('.block-metiers-liste', dom).orNot()) {
            $('.metiers .link', blockMetierListe).hover(function () {
                clearTimeout(timeForNotHover);
                let self = $(this);
                let positionTop = self.position().top;
                $('.metiers .hover', blockMetierListe).removeClass('hover');
                self.parent().addClass('hover');
                $('.link-hover', blockMetierListe).stop().animate({ "top": positionTop }, { duration: 300 });
            }, function () {
                timeForNotHover = setTimeout(function () {
                    $('.metiers .hover', blockMetierListe).removeClass('hover');
                    $('.all').parent().addClass('hover');
                    $('.link-hover', blockMetierListe).stop().animate({ "top": 0 }, { duration: 300 });
                }, 300);
            });
        }

        var formContent;
        if (formContent = $('.form-content').orNot()) {
            Main.initForm(formContent);
        }

    },

    changeColorMenu: function (scrollTop, windowHeight) {
        if ($('body').hasClass("bandeau-has-image")) {
            var blockBandeauTop = $('.block-bandeau-top');
            var headerLogoContent = $(".header-logo-content");
            var limit = blockBandeauTop.position().top + blockBandeauTop.height();

            var btReturn;
            if (btReturn = $(".button-return-content").orNot()) {
                /**
                 * Change color for texte return
                 */
                var btReturnTextePositionBottom = btReturn.position().top + btReturn.height() + scrollTop;
                var btReturnTexteWidth = $(".texte", btReturn).width();
                var btReturnWidthPerc = ((btReturnTextePositionBottom - limit) * 100) / btReturnTexteWidth;
                if (btReturnWidthPerc > 100) {
                    btReturnWidthPerc = 100;
                }
                else if (btReturnWidthPerc < 0) {
                    btReturnWidthPerc = 0;
                }
                $(".texte.black", btReturn).css('width', btReturnWidthPerc + "%");

                /**
                 * Change color for picto return
                 */
                var btReturnPictoPositionBottom = $('.picto', btReturn).offset().top + $('.picto', btReturn).height();
                var btReturnPictoHeight = $(".picto", btReturn).height();
                var btReturnPictoHeightPrc = ((btReturnPictoPositionBottom - limit) * 100) / btReturnPictoHeight;
                if (btReturnPictoHeightPrc > 100) {
                    btReturnPictoHeightPrc = 100;
                }
                else if (btReturnPictoHeightPrc < 0) {
                    btReturnPictoHeightPrc = 0;
                }
                $(".picto.black", btReturn).css('height', btReturnPictoHeightPrc + "%");
            }

            /**
             * Change color for logo
             */
            var logoPositionBottom = $('.logo', headerLogoContent).offset().top + $('.logo', headerLogoContent).height();
            var logoHeight = $(".logo", headerLogoContent).height();
            var logoHeightPrc = ((logoPositionBottom - limit) * 100) / logoHeight;
            if (logoHeightPrc > 100) {
                logoHeightPrc = 100;
            }
            else if (logoHeightPrc < 0) {
                logoHeightPrc = 0;
            }
            $(".logo.black", headerLogoContent).css('height', logoHeightPrc + "%");
        }
    },

    initForm: function (form) {
        var contentLabelInField;
        if (contentLabelInField = $('.label-animate', form).orNot()) {
            $('.label-animate', form).each(function () {
                var self = $(this);
                var input = $("input, textarea", self);
                if (input.val() == "") {
                    $('label', self).removeClass('selected');
                }
                else {
                    $('label', self).addClass('selected');
                }
                input.focus(function () {
                    if ($(this).val() == "") {
                        $('label', self).addClass('selected');
                    }
                }).keyup(function () {
                    if ($(this).val() == "") {
                        $('label', self).removeClass('selected');
                    }
                    else {
                        $('label', self).addClass('selected');
                    }
                }).blur(function () {
                    if ($(this).val() == "") {
                        $('label', self).removeClass('selected');
                    }
                });
                $("select", self).change(function () {
                    if ($(this).val() == "") {
                        $('label', self).removeClass('selected');
                    }
                    else {
                        $('label', self).addClass('selected');
                    }
                });
            });
        }

        var contentChoices;
        if (contentChoices = $(".radio-fields").orNot()) {
            $('input', contentChoices).each(function () {
                var self = $(this);
                var parent = self.parent();
                if (self.is(':checked')) {
                    parent.addClass('isChecked');
                }

                $('.radio-content', parent).click(function () {
                    self.click();
                });

                self.change(function () {
                    $('.isChecked', contentChoices).removeClass('isChecked');
                    if (self.is(':checked')) {
                        parent.addClass('isChecked');
                    }
                    else {
                        parent.removeClass('isChecked');
                    }
                });
            });
        }

        var inputDate;
        if (inputDate = $('.field-date input').orNot()) {
            var element = document.getElementById(inputDate.attr('id'));
            var dateMask = new IMask(element, {
                mask: Date,  // enable date mask

                // other options are optional
                pattern: 'd/m/Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
                // you can provide your own blocks definitions, default blocks for date mask are:
                blocks: {
                    d: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                    },
                    m: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                    },
                    Y: {
                        mask: IMask.MaskedRange,
                        from: 1900,
                        to: 2018,
                        maxLength: 4,
                    }
                },
                // define date -> str convertion
                format: function (date) {
                    var day = date.getDate();
                    var month = date.getMonth() + 1;
                    var year = date.getFullYear();

                    if (day < 10) day = "0" + day;
                    if (month < 10) month = "0" + month;

                    return [day, month, year].join('/');
                },
                // define str -> date convertion
                parse: function (str) {
                    var yearMonthDay = str.split('/');
                    return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
                },

                // optional interval options
                min: new Date(1900, 0, 1),  // defaults to 1900-01-01
                max: new Date(2020, 0, 1),  // defaults to 9999-01-01

                // also Pattern options can be set
                lazy: false
            });
        }

        var inputFile;
        if (inputFile = $('input[type="file"]').orNot()) {
            inputFile.each(function () {
                var inputSelf = $(this);
                var parent = $(this).parent();
                $('.input-file-select', parent).click(function () {
                    inputSelf.click();
                });


                $(this).bind("change", function () {
                    var file = this;
                    var fileType = file.files[0].type; // on récupère le type du fichier de base
                    $(".input-file-select", parent).html(file.files[0].name);
                    $(this).parent().children('label').css("top", "30%");
                });

                var file = this;
                if (file.files[0] != undefined && file.files[0].name) {
                    $(".input-file-select", parent).html(file.files[0].name);
                }

                if (!$.browser.msie) {
                    // Why ?
                    //$('.input-file-select', parent).each(function(){
                    //  $(this).click(function(){
                    //    inputSelf.click();
                    //  });
                    //});
                }
                else {
                    $('.input-file-select', parent).hide();
                    inputSelf.css('left', 0).css('position', "relative");
                }
            });
        }

    }





};