export default class Candidate {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        let self = this;
        $('#submitButton').on('click', () => {
            $().ready(function () {
                $("form").validate({
                    rules: {
                        Lastname: {
                            "required": true
                        },
                        Firstname: {
                            "required": true
                        },
                        City: {
                            "required": true
                        },
                        Email: {
                            "required": true
                        },
                        PhoneNumber: {
                            "required": true
                        },
                        FileCV: {
                            "required": true
                        },
                        FileCoverLetter: {
                            "required": true
                        }
                    },
                    messages: {
                        Lastname: "Merci de renseigner un nom",
                        Firstname: "Merci de renseigner un prénom",
                        City: "Merci de renseigner une ville",
                        Email: "Merci de renseigner un email",
                        PhoneNumber: "Merci de renseigner un numéro de téléphone",
                        FileCV: "Merci d'envoyer un Cv",
                        FileCoverLetter: "Merci d'envoyer une lettre de motivation"
                    }

                });
                //jquerry validator à la main car ca ne fonctionnait pas pour la date de Naissance.
                var countError = 0;
                if ($("#Lastname").val() == "") {
                    $('#Lastname-error').css("display", "block");
                    $('#Lastname-error').html("Merci de renseigner un nom");
                    countError += 1;
                }
                if ($("#Firstname").val() == "") {
                    $('#Firstname-error').css("display", "block");
                    $('#Firstname-error').html("Merci de renseigner un prénom");
                    countError += 1;
                }
                if ($("#City").val() == "") {
                    $('#City-error').css("display", "block");
                    $('#City-error').html("Merci de renseigner une ville");
                    countError += 1;
                }
                if ($("#DateNaissance").val() == "__/__/____") {
                    $('#DateNaissance-error').css("display", "block");
                    $('#DateNaissance-error').html("Merci de renseigner une date de naissance");
                    countError += 1;
                }
                if ($("#Email").val() == "") {
                    $('#Email-error').css("display", "block");
                    $('#Email-error').html("Merci de renseigner un email");
                    countError += 1;
                }
                if ($("#PhoneNumber").val() == "") {
                    $('#PhoneNumber-error').css("display", "block");
                    $('#PhoneNumber-error').html("Merci de renseigner un numéro de téléphone");
                    countError += 1;

                }
                if ($("#FileCV").val() == "") {
                    $('#FileCV-error').css("display", "block");
                    $('#FileCV-error').html("Merci d'envoyer un Cv");
                    countError += 1;
                }
                if ($("#FileCoverLetter").val() == "") {
                    $('#FileCoverLetter-error').css("display", "block");
                    $('#FileCoverLetter-error').html("Merci d'envoyer une lettre de motivation");
                    countError += 1;
                }
                if (countError == 0) {
                    $("#candidateForm").submit();
                }
            })
            
        });

    self.view.on('submit', 'form', () => {
        // On s'appuie sur le component "IMask", à voir si on peut lui dire de ne pas poster le mask
        if (self.view.find('.field-date') && self.view.find('.field-date input').val() === "__/__/____") {
            self.view.find('.field-date input').val('');
        }
    });
    }
}