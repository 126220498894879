const $ = require('jquery');

function dbg()
{
  try {
    console.debug(arguments);
  }
  catch(e)
  {
    for(var i in arguments)
    {
      if (i<5) alert(arguments[i]);
    }
  }
};

function makeid()
{
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for( var i=0; i < 5; i++ )
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

function randomScalingFactor()
{
  return Math.round(Math.random()*10000);
};

function format(c)
{
	if(c > 999)
	{
		var d // le nombre entier ou décimal (avec deux décimales au plus comme les montants monétaires) à  écrire avec des espaces tous les trois chiffres
		while ((d=c.toString().replace(/(\d)([\d]{3})(\.|\s|\b)/,"$1 $2$3")) && d!=c) c=d;
	}
	return c;
}

jQuery.fn.extend({
	  orNot: function() {
	    return this.length == 0 ? false : this;
	  }
});
jQuery.fn.tagName = function() {
  return this.get(0).tagName.toLowerCase();
};

jQuery.fn.fadeTo = function(speed,to,callback) { 
    return this.animate({opacity: to}, speed, function() { 
        if (to == 1 && jQuery.browser.msie)  
            this.style.removeAttribute('filter');  
        if (jQuery.isFunction(callback)) 
            callback();  
    }); 
}; 

var srollbarWidth = 0;
function scrollbarWidth() {
	if(!srollbarWidth)
	{
		var div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div>');
    // Append our div, do our calculation and then remove it
    $('body').append(div);
    var w1 = $('div', div).innerWidth();
    div.css('overflow-y', 'scroll');
    var w2 = $('div', div).innerWidth();
    $(div).remove();
    srollbarWidth = (w1 - w2);
	}
  return srollbarWidth;
}

function hexacolor(colorval) {
  var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  delete(parts[0]);
  for (var i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length == 1) parts[i] = '0' + parts[i];
  }
  return '#' + parts.join('');
}


if ($.validator !== undefined) {
  $.validator.addMethod('number', function(value, element) {
    value = value.replace(',', ".");
    return this.optional( element ) || /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test( value );
  });
}
