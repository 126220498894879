export function init() {
  Popup.init();
};

export function open(popupClass, closePopup, transitionMultiPopup) {
  Popup.open(popupClass, closePopup, transitionMultiPopup);
};

export function close() {
  Popup.close();
};

export function viewContent(template, callback, scrollToTop) {
  Popup.viewContent(template, callback, scrollToTop);
};

export function calculateSize() {
  Popup.calculateSize();
};



var searchTime;
var searchIsNumeric;
var elementRightSelectedKey = "";
var timeAdressIsDiff;
var activateClose = true;
var isScrollToTop = true;
var activateTransitionMultiPopup = true;

var Popup = {

	init: function()
	{
		$(document).on('click', '#popupOverlay', function() {
			Popup.close();
		});
		$(document).on('click', '.popupClose', function() {
			Popup.close();
		});
	},

	open: function(popupClass, closePopup, transitionMultiPopup)
	{
	  if(popupClass)
	  {
	    $('#popup').addClass(popupClass);
	  }
		activateClose = closePopup == undefined ? true : closePopup;
		activateTransitionMultiPopup = transitionMultiPopup == undefined ? true : transitionMultiPopup;
		
		if($('body').hasClass('popupIsView'))
		{
			if(activateTransitionMultiPopup)
			{
				$('#popup #popupOverflow').stop().animate({opacity: 0, height: 0}, {duration: 300});
				$('#popup .loader').css('display', "block").addClass('view load');
				
				$('#popup #popupLoader .contentLoader').css('display', "block").addClass('animate');
				$('#popup #popupLoader .contentLoader .contentLoaderPicto').addClass('rotate');
				var time = setTimeout(function(){
					$('#popup #popupLoader .contentLoader').css('opacity', 1);
				}, 100 );
			}
		}
		else
		{
			$('#overlay').css('display', "block").addClass('animate');
			$('#popup #popupOverflow').removeAttr('style');
			$('#popup').css('display', "block").addClass('animate');
		}
	},
	
	close: function()
	{
		if(activateClose)
		{
			$('body').removeClass('popupIsView');
			var time = setTimeout(function(){
				$('#popup #popupContent').html();
				$('#overlay').removeClass('animate').css('display', "none");
				$('#popup').removeClass('animate').css('display', "none");
				$('#popup').removeClass('isAbsolute').removeClass('isBig');
        $('#popup').removeAttr('class');
        $('#popup').removeAttr('style');
			}, 300);
		}
	},
	
	viewContent: function(template, callback, scrollToTop)
	{
    isScrollToTop = scrollToTop == undefined ? true : scrollToTop;
		if($('body').hasClass('popupIsView'))
		{
			var time = setTimeout(function(){
				$('#popup #popupContent').html(template);
				if($('#popup #popupContent .popupIsBig').length)
				{
					$('#popup').addClass('isBig');
				}
				else
				{
					$('#popup').removeClass('isBig');
				}
				
				if($('#popup #popupContent').height() > $(window).height()-200)
				{
					$('#popup').addClass('isAbsolute');
					if(isScrollToTop == true)
					{
					  $("html, body").stop().animate({scrollTop: 0}, {duration: 300, easing:"easeInOutQuart"});
					}
					else
					{
					  $('#popup').css('top', ($(window).scrollTop()+30)+"px");
					}
				}
				else
				{
					$('#popup').removeClass('isAbsolute');
				}
				
				if (typeof callback === "function") {
		      callback($('#popup #popupContent'));
		    }
		    $('#popup #popupLoader .contentLoader').css('opacity', 0);
				var time = setTimeout(function(){
					$('#popup #popupLoader .contentLoader').css('display', "block").addClass('animate');
					$('#popup #popupLoader .contentLoader .contentLoaderPicto').addClass('rotate');
				}, 200 );
		    
		    
				$('#popup .loader').removeClass('view load').css('display', "none");
				var timeBis = setTimeout(function(){
					$('#popup #popupOverflow').stop().animate({opacity: 1, height: $('#popup #popupContent').height()}, {duration: 300, complete: function(){
						$('#popup #popupOverflow').css('height','auto');
					}});
				}, 100);
			}, 300);
		}
		else
		{
			$('#popup #popupContent').html(template);
			if($('#popup #popupContent .popupIsBig').length)
			{
				$('#popup').addClass('isBig');
			}
			else
			{
				$('#popup').removeClass('isBig');
			}
			if($('#popup #popupContent').height() > $(window).height()-200)
			{
				$('#popup').addClass('isAbsolute');
				if(isScrollToTop == true)
        {
          $("html, body").stop().animate({scrollTop: 0}, {duration: 300, easing:"easeInOutQuart"});
        }
        else
        {
          $('#popup').css('top', ($(window).scrollTop()+30)+"px");
        }
			}
			else
			{
				$('#popup').removeClass('isAbsolute');
			}
			
			if (typeof callback === "function") {
	      callback($('#popup #popupContent'));
	    }
			var time = setTimeout(function(){
				$('body').addClass('popupIsView');
			}, 100);
		}
	},
	
	calculateSize: function()
	{
	  if($('#popup #popupContent').height() > $(window).height()-200)
    {
      $('#popup').addClass('isAbsolute');
      $("html, body").stop().animate({scrollTop: 0}, {duration: 300, easing:"easeInOutQuart"});
    }
    else
    {
      $('#popup').removeClass('isAbsolute');
    }
	}

};
