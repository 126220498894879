export default class AjaxDropdownlist {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        let self = this;

        let url = self.view.data("url");
        let def = self.view.data('default');

        let event = self.view.data('event');

        let data = {};
        if (typeof self.view.data('exclude') !== 'undefined') {
            data['exclude'] = self.view.data('exclude');
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            success: function (result) {
                self.view.empty();
                $(result).each(function () {
                    var opt = $('<option/>')
                        .attr('value', this.value)
                        .text(this.text);
                    if (def == this.value)
                        opt.attr('selected', 'selected');
                    opt.appendTo(self.view);
                });

                if (typeof event !== 'undefined' && typeof eval(event) === 'function')
                    eval(event)();
            }
        });

    }
}