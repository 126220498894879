import { setTimeout } from "timers";

export default class LoadVideo {

    constructor($view) {
        this.view = $view;
        this.Init();
    }

    Init() {
        var self = this;
        
        self.Blur();
    }

    Blur() {
        var self = this;
        var src = $('#video').attr('src');
        $("#buttonDisplayVideo").on('click', () => {
            $('#video').attr("src", src);
            $("#container").addClass("blur");
        });

        $('body').on("click", () => {
            let modal = $('.modal');
            setTimeout(() => {
                if ($(modal).css("display") === "none") {
                    $("#container").removeClass("blur");
                    $('#video').attr("src", '');
                };
            }, 300)
        })
    }
}
