export default class CookieBanner {

    constructor($view) {
        this.view = $view;
        this.Init();
    }

    Init() {
        var self = this;
        self.cookieBanner();
    }

    cookieBanner() {

        var disableStr = 'ga-disable';

        if (document.cookie.indexOf('hasConsent=false') > -1)
            window[disableStr] = true;
        //Cette fonction retourne la date d’expiration du cookie de consentement 

        function getCookieExpireDate() {
            var cookieTimeout = 86400000;// Le nombre de millisecondes que font 24h
            var date = new Date();
            date.setTime(date.getTime() + cookieTimeout);
            var expires = "; expires=" + date.toGMTString();
            return expires;
        }

        // Fermeture de la demande de consentement
        function closeConsent() {

            var clickChoose;
            $("#reject").on("click", function () {
                clickChoose = 'false'
                closeCookieBanner(clickChoose)
            });

            $("#accept").on("click", function () {
                clickChoose = 'true'
                closeCookieBanner(clickChoose)
            });


        }

        function closeCookieBanner(clickChoose) {
            $("#cookie-banner").css('display', 'none');
            $('body').css('margin-top', '0px');
            document.cookie = 'hasConsent=' + clickChoose + '; ' + getCookieExpireDate() + ' ; path=/';
        }
        window.closeConsent = closeConsent();

        // Cette fonction est appelée pour afficher la demande de consentement
        function askConsent() {
            $("#cookie-banner").css('display', 'block');
            $('body').css('margin-top', '50px');
        }

        // Retourne la chaine de caractère correspondant à nom=valeur
        function getCookie(NomDuCookie) {
            if (document.cookie.length > 0) {
                var begin = document.cookie.indexOf(NomDuCookie + "=");
                if (begin !== -1) {
                    begin += NomDuCookie.length + 1;
                    var end = document.cookie.indexOf(";", begin);
                    if (end === -1) end = document.cookie.length;
                    return unescape(document.cookie.substring(begin, end));
                }
            }
            return null;
        }

        //Ce bout de code vérifie que le consentement n'a pas déjà été obtenu avant d'afficher
        // la baniére
        var consentCookie = getCookie('hasConsent');
        if (!consentCookie) {//L'utilisateur n'a pas encore de cookie de consentement
            var referrer_host = document.referrer.split('/')[2];
            //if (referrer_host != document.location.hostname) {
                //si il vient d'un autre site on désactive le tracking et on affiche la demande de consentement
                window[disableStr] = true;
                window[disableStr] = true;
                window.onload = askConsent;
            //}
        }
    }
}